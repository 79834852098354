<template>
  <v-container fluid class="pa-0">
    <item-title :title="options.item.nameRu" class="mb-2" />
    <v-row>
      <v-col class="d-flex">
        <item-props
          class="flex-grow-1"
          :address="options.item.address"
          :bin="options.item.bin"
          :hotlinePhone="options.item.phone"
        />
      </v-col>
      <v-col>
        <item-contact
          :organizationId="options.item.id"
          :registerDate="options.item.created"
        />
      </v-col>
    </v-row>
    <v-tabs
      class="elevation-2 mt-5"
      dark
      :grow="true"
      show-arrows
      v-model="activeItem"
    >
      <v-tabs-slider :color="colorSlider"></v-tabs-slider>
      <v-tab
        v-for="tab in tabs"
        :key="tab.id"
        :href="'#' + `tab-${tab.id}`"
        @click="selectTab(tab)"
        :style="{ color: colorText }"
        class="text-h4"
      >
        {{ tab.name }}
      </v-tab>
      <v-tabs-items v-model="activeItem">
        <v-tab-item
          v-for="(item, index) in componentList"
          :key="index"
          :value="`tab-${tabs[index].id}`"
        >
          <component :is="item.name" v-bind="item.props" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>
<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "ItemProperties",

  components: {
    ItemTitle: () =>
      import("@/components/reference/industry/IndustryTitle.vue"),
    ItemContract: () =>
      import("@/components/reference/industry/customer/CItemContract.vue"),
    ItemProductionFacility: () =>
      import("@/components/reference/industry/customer/CItemProductionFacility.vue"),
    ItemDevice: () => import("@/components/reference/industry/ItemDevice.vue"),
    ItemProps: () => import("@/components/reference/industry/customer/CItemProps.vue"),
    ItemContact: () => import("@/components/reference/industry/customer/CItemContact.vue")
  },
  computed: {
    options: get("reference/optionsIndustry")
  },
  data() {
    return {
      activeItem: 0,
      // pathItem: "/app/customer/passport/",
      colorText: "#BBDEFB",
      colorSlider: "#BBDEFB",
      tabs: [],
      organizationId: localStorage.getItem('organizationId'),
      componentList: [
        {
          name: "item-contract",
          props: {
            organizationId: parseInt(this.organizationId)
          }
        },
        {
          name: "item-production-facility",
          props: {
            organizationId: parseInt(this.organizationId)
          }
        }
        // Скрыто времено по распоряжению Смагулова Аскара 07.04.2022
        // {
        //   name: "item-device",
        //   props: "",
        // },
      ],
      bin: localStorage.getItem('bin'),
      name: "",
    };
  },
  methods: {
    ...mapActions({
      fetchIndustry: "reference/fetchIndustryOrganizationItem",
      setIndustryItem: "reference/setIndustryOrganization",
    }),
    initialize() {
      // const organizationId = localStorage.getItem('organizationId');
      this.fetchIndustry(this.organizationId);
      this.setIndustryItem(this.organizationId);
      const item = JSON.stringify(this.options.item);
      localStorage.setItem("item", item);
      // this.$router.push({ path: this.pathItem + `${this.organizationId}` }).catch((e) => {});
      if (localStorage.getItem("item")) {
        try {
          const item = JSON.parse(localStorage.getItem("item"));
          this.options.item = item;
        } catch (e) {
          localStorage.removeItem("item");
        }
      }
      this.organizationId = localStorage.getItem('organizationId');
      this.tabs = this.options.properties;
    },
    selectTab(tab) {
      if (this.currentTab !== tab) {
        this.currentTab = tab;
        this.$emit("select-tab", this.currentTab);
      }
    }
  },

  created() {
    this.initialize(); // не перемещать
  }
});
</script>
